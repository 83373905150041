<template>
	<div class="swc-payment">
		<div class="header">
			<img src="@/assets/images/icons/ic_back_nav.svg" alt="back" class="cursor-pointer ml-auto" @click="$router.go(-1)">
		</div>
		<div class="swc-payment__container">
			<div class="swc-invoice">
				<h3 class="title text-center">Please confirm and submit <br> your order</h3>
				<div class="text-left mt-4">
					<h4 class="font-weight-normal mb-3">Your SWC</h4>
					<div class="swc__info">
						<img src="@/assets/images/sidebar/sb_swc.png" alt="SWC" class="img-fluid swc__img">
						<div class="ml-2">
							<h4 v-if="!loading_payment" class="m-0 swc__title">{{ detail_payment.user_swc ? detail_payment.user_swc : '0' }}</h4>
							<div v-else class="w-100">
								<b-skeleton animation="wave" width="100%"></b-skeleton>
							</div>
                            <h6 class="m-0 swc__subtitle">SWC</h6>
						</div>
					</div>
					<div v-if="!loading_payment" class="swc__summary mt-4">
						<h5 class="mb-0 font-weight-normal" style="color:#8C8C8C;">Order Summary</h5>
						<div class="d-flex align-items-center justify-content-between mt-4">
							<h5>Package Name</h5>
							<h4>{{ detail_payment.package_name }}</h4>
						</div>
						<div v-if="detail_payment.question_generates" class="d-flex align-items-center justify-content-between mt-3">
							<h5>Allocations</h5>
							<h4>+{{ detail_payment.question_generates }}</h4>
						</div>
						<p v-if="detail_payment.question_generates" style="color:#8C8C8C; font-size:12px;" class="text-right">question</p>
						<div v-if="detail_payment.audio_duration" class="d-flex align-items-center justify-content-between mt-3">
							<h5>Allocations</h5>
							<h4>+{{ detail_payment.audio_duration }}</h4>
						</div>
						<p v-if="detail_payment.audio_duration" style="color:#8C8C8C; font-size:12px;" class="text-right">minutes</p>
						<div v-if="detail_payment.video_duration" class="d-flex align-items-center justify-content-between mt-3">
							<h5>Allocations</h5>
							<h4>+{{ detail_payment.video_duration }}</h4>
						</div>
						<p v-if="detail_payment.video_duration" style="color:#8C8C8C; font-size:12px;" class="text-right">video</p>
					</div>
					<div v-else class="swc__summary mt-4">
						<h5 class="mb-0 font-weight-normal" style="color:#8C8C8C;">Order Summary</h5>
						<div class="d-flex align-items-center justify-content-between mt-4">
							<h5>Package Name</h5>
							<b-skeleton animation="wave" width="30%"></b-skeleton>
						</div>
						<div class="d-flex align-items-center justify-content-between mt-3 mb-2">
							<h5>Allocations</h5>
							<b-skeleton animation="wave" width="30%"></b-skeleton>
						</div>
					</div>
					<div class="swc-payment__total mt-4">
						<h5 class="font-weight-normal">TOTAL</h5>
						<h3 v-if="!loading_payment">{{ detail_payment.price_swc }} SWC</h3>
						<b-skeleton v-else animation="wave" width="25%"></b-skeleton>
					</div>
					<button class="btn-payment btn mt-4" @click="$bvModal.show('input-pin-modal')"><h5>Pay Now</h5></button>
				</div>
			</div>
		</div>
		<InputPin :package-id="$route.query.key" @forgot-pin="handleForgotPin"/>
		<CreatePin name="forgot-create-pin-modal"/>
        <VerificationCode v-on:otp-success="openCreatePin"/>
	</div>
</template>

<script>
import InputPin from '@/components/payment/modals/InputPin'
import { getDetailPaymentSwc } from '@/services/swc_payment/swc_payment.service'
import { requestSetPin } from '@/services/pin/pin.service'

import CreatePin from '@/components/pin/CreatePin'
import VerificationCode from '@/components/pin/VerificationCode'

export default {
	components : {
		InputPin,
		CreatePin,
		VerificationCode
	},

	data() {
		return {
			status_subscriptions : [],
			detail_payment: [],

			loading_payment: false
		}
	},

	mounted() {
		this.getDetailPayment(this.$route.query.key);
	},

	methods: {

		getDetailPayment(id){
			this.loading_payment = true
			getDetailPaymentSwc(id)
			.then((response) => {
				if(response.status) {
					this.detail_payment = response.data
					this.loading_payment = false
				} else {
					console.log(response)
				}
			})
		},

		handleForgotPin(){
			this.$bvModal.hide('input-pin-modal')
            this.requestPin()
        },

		requestPin(){
			requestSetPin()
			.then((response) => {
				if(response.status) {
					this.$bvModal.show('verif-code-modal')
				} else {
					if(response.response?.response?.status == 429) {
						this.alertFail("Too many requests, please try again later.")
					}
				}
			})
		},

		openCreatePin() {
			this.$bvModal.hide('verif-code-modal')
			this.$bvModal.show('forgot-create-pin-modal')
		},
	}
}
</script>

<style scoped>
.swc-payment {
	height: 100vh
}

.swc-payment .header {
	width: 100%;
    padding: 20px 50px;
    text-align: left;
    border-bottom: 0.1px solid #1C1C1C;
}

.swc-payment__container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 90%;
}

.swc-invoice {
	width: 35%;
	color: white;
}

.title {
	font-size: 30px;
}

.swc__info {
	background: #1F1F1F;
	border-radius: 8px;
	padding: 20px 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.swc__title {
    color: white;
    font-weight: 900;
    font-size: 20px;;
}

.swc__subtitle {
    color: #8C8C8C;
    font-size: 14px !important;
    font-weight: 100;
}

.swc__summary {
	border-bottom: 1px dashed #383838;
}

.swc__summary h4 {
	font-size: 20px;
	font-weight: 900;
}

.swc-payment__total {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.swc-payment__total h3 {
	font-size: 24px;
	font-weight: 900;
}

.btn-payment {
	background-color: #6D75F6;;
	border-radius: 8px;
	padding: 15px;
	width: 100%;
	color: white;
}

.btn-payment h5 {
	font-weight: 900;
}
</style>