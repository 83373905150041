<template>
    <div>
        <b-modal id="input-pin-modal" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="md">
          <div class="d-flex justify-content-end mb-2">
            <button
              class="btn btn-close d-flex justify-content-center align-items-center close-image"
              @click="$bvModal.hide('input-pin-modal')"
            >
              <b-icon icon="x"></b-icon>
            </button>
          </div>
          <div class="input-pin my-3">
            <h3 class="input-pin__header">Input Your PIN</h3>
            <h5 class="font-weight-bold mt-2">Enter your PIN to make payment</h5>
            <div style="display: flex; flex-direction: row;" class="my-4 mx-md-3 verivikasi-pin d-flex justify-content-center">
                <v-otp-input
                    ref="otpInput"
                    :input-classes="validateInput ? 'otp-input form-control text-center not-match' : 'otp-input form-control text-center'"
                    input-type="password"
                    separator="&nbsp;&nbsp;&nbsp;"
                    :num-inputs="6"
                    :should-auto-focus="true"
                    :is-input-num="true"
                    @on-change="handleOnChange"
                    @on-complete="handleOnComplete"
                />
            </div>
            <h5 class="cursor-pointer" style="color:#6D75F6;" @click="$emit('forgot-pin')">Forgot PIN?</h5>
          </div>
        </b-modal>
    </div>
</template>

<script>
import { confirmPin, paymentRemoveWM } from '@/services/swc_payment/swc_payment.service'

export default {
  props: {
    packageId : {
      value: String,
      default: null,
      required: true
    },
    watermark: {
      value: Boolean,
      default: false
    }
  },

	data() {
    return {
      id : this.packageId,
      otp: null,
      pin_wrong: null
    }
	},

  emits: ['forgot-pin'],

  computed : {
		validateInput() {
			return this.pin_wrong != null && this.pin_wrong == true
		}
	},

	methods: {
		handleOnChange(val){
      this.pin_wrong = null
      this.otp = val
		},
		handleOnComplete(val) {
      this.otp = val


      if(this.watermark) {
        let formData = {
          pin : this.otp,
          price_swc : 50
        }
        paymentRemoveWM(formData, this.id)
        .then((response) => {
          if(response.status) {
            this.pin_wrong = false
            this.$emit('success:remove-watermark')
            this.$bvModal.hide('input-pin-modal')
          } else {
            this.pin_wrong = true
          }
        })

      } else {
        let formData = {
          pin : this.otp,
          top_up_swc_trans_id : this.id ? this.id : this.$route.query.key
        }
        confirmPin(formData)
        .then((response) => {
          if(response.status) {
            this.$router.push({ name : 'Payment-Success'})
            this.pin_wrong = false
          } else {
            this.pin_wrong = true
          }
        })
      }

		}
	},

  watch: {
    packageId(val) {
      this.id = val
    }
  }
}
</script>

<style scoped>
.btn-purple {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
    color: white;
    box-shadow: none !important;
}
</style>

<style>
@font-face {
  font-family: "Avenir Next";
  src: url("../../../assets/font/AvenirNextLTPro-Regular.otf");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../../assets/font/AvenirNextLTPro-Bold.otf");
  font-weight: 900;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../../../assets/font/AvenirNext-DemiBold.ttf");
  font-weight: bold;
}
@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap");

#input-pin-modal .modal-content{
    border-radius: 12px !important;
    background-color: #222222;
    font-family: "Avenir Next", Avenir, Helvetica, Arial, sans-serif;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

.input-pin {
	color: white;
	text-align: center;
}

.input-pin__header {
	font-size: 30px;
	font-weight: 900;
}

.btn-close {
  background-color: #ededed;
  border-radius: 50%;
  width: 15px;
  height: 26px;
}
</style>

<style scoped lang="less">
  .otp-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    &.error {
      border: 1px solid red !important;
    }
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>

<style>
.verivikasi-pin .form-control{
    color: white;
    background-color:transparent;
    border: 1px solid #383838;
    max-width: 45px;
	height: 50px;
    padding: 0;
}

.verivikasi-pin input:focus{
    color: white;
    background-color:transparent;
}

.not-match {
	border: 1px solid #DF2723 !important;
}
</style>